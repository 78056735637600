<template>
    <div class="p-grid">
        <div class="p-col-12">
            <div class="card">
                <h4 class="p-mt-1">Merchant Infomation</h4>
                <Toast />
                <ConfirmDialog></ConfirmDialog>
                <Toolbar>
                    <template #start>
                        <Button icon="pi pi-plus" label="Add Merchant" class="p-button-success mr-2" @click="openNew()" />
                        <InputText type="text" placeholder="ชื่อร้านค้า" class="mr-2" v-model="state.nameS" />
                    </template>

                    <template #end>
                        <Button icon="pi pi-search" label="Search" class="p-button-info mr-2" @click="getData()" />
                        <Button icon="pi pi-refresh" label="Reset" class="p-button-warning" @click="reset()" />
                    </template>
                </Toolbar>
                <DataTable
                    :autoLayout="true"
                    :value="state.merchants"
                    :lazy="true"
                    stripedRows
                    :paginator="true"
                    :rows="50"
                    :totalRecords="state.totalRecords"
                    :loading="state.loading"
                    :rowsPerPageOptions="[50, 100, 200]"
                    paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                    currentPageReportTemplate="Showing {first} to {last} of {totalRecords}"
                    dataKey="id"
                    class="p-datatable-sm"
                >
                    <Column field="no" :header="$t('common.number')" :style="{ width: '100px', textAlign: 'center' }" headerClass="center">
                        <template #body="slotProps">
                            {{ slotProps.index + 1 }}
                        </template>
                    </Column>
                    <Column field="id" header="Client ID" sortable> </Column>
                    <Column field="merchantId" header="Merchant ID" sortable> </Column>
                    <Column field="name" header="ชื่อร้านค้า"></Column>
                    <Column field="username" header="เจ้าของ"></Column>
                    <Column field="bankName" header="ธนาคาร"></Column>
                    <Column field="email" header="อีเมลที่ใช้สมัครกับธนาคาร"></Column>
                    <Column field="desc" header="รายละเอียด"></Column>
                    <Column field="createdOn" header="Create Dt" :style="{ textAlign: 'center' }" headerClass="center" sortable>
                        <template #body="{ data }">
                            {{ useDateFormat(data.createdOn, 'YYYY-MM-DD HH:mm').value }}
                        </template>
                    </Column>
                    <Column headerStyle="width: 8rem" headerClass="p-text-center" bodyClass="p-text-center">
                        <template #body="slotProps">
                            <Button icon="pi pi-check-square" class="p-button-rounded p-button-success m-1" @click="openEdit(slotProps.data)" />
                            <Button icon="pi pi-trash" class="p-button-rounded p-button-danger m-1" @click="deleteMerchant(slotProps.data)" />
                        </template>
                    </Column>
                    <template #footer> In total there are {{ formatInt(state.totalRecords) }} item. </template>
                </DataTable>
                <Dialog v-model:visible="state.dialog" header="Merchant Infomation" :modal="true" :breakpoints="{ '960px': '75vw', '640px': '100vw' }" :style="{ width: '50vw' }">
                    <Message v-if="state.errorMessage" severity="error">{{ state.errorMessage }}</Message>
                    <div class="p-fluid">
                        <div class="field">
                            <label>Client ID *</label>
                            <InputText v-model="state.form.id" :disabled="state.form.mode == 'U'" />
                        </div>
                        <div class="field">
                            <label>ชื่อร้านค้า *</label>
                            <InputText v-model="state.form.name" />
                        </div>
                        <div class="field">
                            <label>Merchant ID *</label>
                            <InputText v-model="state.form.merchantId" />
                        </div>
                        <div class="field">
                            <label>Secret Key *</label>
                            <InputText v-model="state.form.secretkey" />
                        </div>
                        <div class="field">
                            <label>ธนาคาร *</label>
                            <Dropdown v-model="state.form.bankId" :options="state.banks" optionLabel="bankNameTh" optionValue="id" />
                        </div>
                        <div class="field">
                            <label>เจ้าของ *</label>
                            <Dropdown v-model="state.form.userMapping" :options="state.agents" optionLabel="username" optionValue="id" />
                        </div>
                        <div class="field">
                            <label>อีเมลที่ใช้สมัครกับธนาคาร</label>
                            <InputText v-model="state.form.email" />
                        </div>
                        <div class="field">
                            <label>รายละเอียด</label>
                            <Textarea v-model="state.form.desc" rows="3" />
                        </div>
                    </div>
                    <template #footer>
                        <Button label="Save" icon="pi pi-check" class="p-button-success" @click="saveMerchant" autofocus></Button>
                        <Button label="Cancel" icon="pi pi-times" class="p-button-danger" @click="close()"></Button>
                    </template>
                </Dialog>
            </div>
        </div>
    </div>
</template>

<script setup>
import { computed, onMounted, reactive } from 'vue';
import { useToast } from 'primevue/usetoast';
// import { useConfirm } from 'primevue/useconfirm';
import { useAuthStore } from '@/store/auth';
import ComboService from '@/service/ComboService';
import UserMerchantService from '@/service/UserMerchantService';
import { useRouter } from 'vue-router';
import { formatInt } from '@/common/Utils';
import { useDateFormat } from '@vueuse/shared';
import { useI18n } from 'vue-i18n';

const authUserStore = useAuthStore();
const router = useRouter();
// const confirm = useConfirm();
const toast = useToast();
const { t } = useI18n();
const auth = computed(() => authUserStore.user);

const state = reactive({
    errorMessage: '',
    loading: false,
    dialog: false,
    nameS: '',
    agents: [],
    merchants: [],
    banks: [],
    totalRecords: 0,
    form: {
        mode: '',
        id: '',
        name: '',
        desc: '',
        merchantId: '',
        secretkey: '',
        accountName: '',
        accountNumber: '',
        bankId: null,
        userMapping: null,
        email: '',
        creator: '',
        updated: '',
    },
});

const getCombo = async () => {
    try {
        state.loading = true;
        const response = await ComboService.findBanksList();
        state.banks = response.data.data;
        const response2 = await ComboService.findUserAgent();
        state.agents = response2.data.data;
    } catch (error) {
        if (error.response.status === 403) {
            router.push({ name: 'Login' });
        }
        console.log(JSON.stringify(error));
        state.errorMessage = t('0999', [error.message]);
    } finally {
        // state.isLoading = false;
        state.loading = false;
    }
};

const getData = async () => {
    try {
        state.loading = true;
        const response = await UserMerchantService.findUserMerchantList(state.nameS, 0, 100);
        state.merchants = response.data.data;
        state.totalRecords = response.data.totalItems;
    } catch (error) {
        if (error.response.status === 403) {
            router.push({ name: 'Login' });
        }
        console.log(JSON.stringify(error));
        state.errorMessage = t('0999', [error.message]);
    } finally {
        // state.isLoading = false;
        state.loading = false;
    }
};

const openNew = async () => {
    try {
        state.errorMessage = '';
        state.form.mode = 'A';
        state.form.id = '';
        state.form.name = '';
        state.form.desc = '';
        state.form.creator = '';
        state.form.merchantId = '';
        state.form.secretkey = '';
        state.form.accountName = '';
        state.form.accountNumber = '';
        state.form.bankId = null;
        state.form.email = '';
        state.form.userMapping = null;
        state.dialog = true;
    } catch (error) {
        console.log(JSON.stringify(error));
        state.errorMessage = t('0999', [error.message]);
    } finally {
        state.loading = false;
    }
};

const openEdit = async (merchant) => {
    try {
        state.errorMessage = '';
        state.form.mode = 'U';
        state.form.id = merchant.id;
        state.form.name = merchant.name;
        state.form.desc = merchant.desc;
        state.form.creator = merchant.creator;
        state.form.merchantId = merchant.merchantId;
        state.form.bankId = merchant.bankId;
        state.form.secretkey = merchant.secretkey;
        state.form.accountName = merchant.accountName;
        state.form.accountNumber = merchant.accountNumber;
        state.form.accountNumber = merchant.accountNumber;
        state.form.email = merchant.email;
        state.form.userMapping = merchant.userId;
        state.dialog = true;
    } catch (error) {
        // console.log(JSON.stringify(error));
        state.errorMessage = t('0999', [error.message]);
    } finally {
        state.loading = false;
    }
};

const saveMerchant = async () => {
    try {
        if (state.form.id == null || state.form.id == '') {
            state.errorMessage = 'Client ID';
            return;
        }
        if (state.form.merchantId == null || state.form.merchantId == '') {
            state.errorMessage = 'Merchant Id';
            return;
        }
        if (state.form.secretkey == null || state.form.secretkey == '') {
            state.errorMessage = 'Secret Key';
            return;
        }
        if (state.form.name == null || state.form.name == '') {
            state.errorMessage = 'ชื่อร้านค้า';
            return;
        }
        if (state.form.bankId == null) {
            state.errorMessage = 'ธนาคาร';
            return;
        }
        if (state.form.userMapping == null || state.form.userMapping == '') {
            state.errorMessage = 'เจ้าของ';
            return;
        }
        if (state.form.mode == 'A') {
            state.form.creator = auth.value.username;
            state.form.updated = auth.value.username;
            const { data: response } = await UserMerchantService.saveUserMerchantNew(state.form);
            //console.log(response);
            if (response.status === 'OK') {
                toast.add({ severity: 'info', summary: t('common.process'), detail: t('common.complete.add'), life: 3000 });
                getData();
                state.dialog = false;
            } else {
                toast.add({ severity: 'error', summary: t('common.process'), detail: t(response.code), life: 3000 });
            }
        } else {
            state.form.updated = auth.value.username;
            const { data: response } = await UserMerchantService.saveUserMerchantUpdate(state.form);
            //console.log(response);
            if (response.status === 'OK') {
                toast.add({ severity: 'info', summary: t('common.process'), detail: t('common.complete.update'), life: 3000 });
                getData();
                state.dialog = false;
            } else {
                toast.add({ severity: 'error', summary: t('common.process'), detail: t(response.code), life: 3000 });
            }
        }
    } catch (error) {
        // console.log(error);
        state.errorMessage = t('0999', [error.message]);
    }
};

const close = async () => {
    state.dialog = false;
};

const reset = async () => {
    state.nameS = '';
    getData();
};

onMounted(() => {
    getCombo();
    getData();
});
</script>
<style lang="scss">
.center {
    .p-column-header-content {
        text-align: center;
        display: block !important;
    }
}

.right {
    .p-column-header-content {
        text-align: right;
        display: block !important;
    }
}
</style>
