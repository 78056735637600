import api from './api';

class UserMerchantService {
    findUserMerchantList(name, page, size) {
        return api.get(`/api/v1/userMerchant/findUserMerchantList?name=${name}&page=${page}&size=${size}`);
    }

    findUserMerchant(id) {
        return api.get(`/api/v1/userMerchant/findUserMerchant?id=${id}`);
    }

    saveUserMerchantNew(userMerchant) {
        return api.post('/api/v1/userMerchant/saveUserMerchantNew', JSON.stringify(userMerchant));
    }

    saveUserMerchantUpdate(userMerchant) {
        return api.post(`/api/v1/userMerchant/saveUserMerchantUpdate`, JSON.stringify(userMerchant));
    }
}
export default new UserMerchantService();
